import {LinksFunction}                                                                                     from "@remix-run/node";
import {isRouteErrorResponse, Links, Meta, Outlet, Scripts, ScrollRestoration, useLocation, useRouteError} from "@remix-run/react";
import {captureRemixErrorBoundaryError}                                                                    from "@sentry/remix";
import {posthog}                                                                     from "posthog-js";
import {ReactNode, useEffect}                                                        from "react";
import {Footer}                                                                      from "~/components/Footer";
import {NavigationBar}                                                               from "~/components/NavigationBar";
import {Toaster}                                                                     from "./components/ui/toaster";
import "./tailwind.css";

export const links: LinksFunction = () => [
    {
        rel  : "icon",
        type : "image/png",
        href : "/favicon-48x48.png",
        sizes: "48x48",
    },
    {
        rel : "shortcut icon",
        href: "/favicon.ico",
    },
    {
        rel  : "apple-touch-icon",
        href : "/apple-touch-icon.png",
        sizes: "180x180",
    },
    {
        rel : "manifest",
        href: "/site.webmanifest",
    },
    {
        rel : "preconnect",
        href: "https://fonts.googleapis.com",
    },
    {
        rel        : "preconnect",
        href       : "https://fonts.gstatic.com",
        crossOrigin: "anonymous",
    },
    {
        rel : "stylesheet",
        href: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
    },
    {
        rel : "stylesheet",
        href: "https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap",
    },
];

export function Layout({children}: { children: ReactNode }) {
    return (
        <html lang="nl">
        <head>
            <meta charSet="utf-8"/>
            <meta name="viewport" content="width=device-width, initial-scale=1"/>
            <Meta/>
            <meta name="apple-mobile-web-app-title" content="CHKS"/>
            <Links/>
        </head>
        <body className={"lp_body subpixel-antialiased"}>
        <Toaster/>
        <NavigationBar/>
        {children}
        <Footer/>
        <ScrollRestoration/>
        <Scripts/>
        </body>
        </html>
    );
}

export const ErrorBoundary = () => {
    const error = useRouteError();
    captureRemixErrorBoundaryError(error);

    if (isRouteErrorResponse(error)) {
        if (error.status === 404) {
            window.location.href = "/";
        }
    }

    return (
        <div className={"text-white font-semibold text-center w-full min-h-screen text-2xl"}>
            Deze pagina bestaat niet. Je word teruggeleid.
        </div>
    );
};

export default function App() {
    const PosthogInit = () => {
        useEffect(() => {
            if (process.env.NODE_ENV === "production") {
                posthog.init("phc_NJLS8jFXZBBGuiHbkTmXsPfUjBEQ32WOpn9boRMZx8I", {
                    api_host       : "https://eu.i.posthog.com",
                    person_profiles: "identified_only",
                    persistence    : "memory",
                });
            }
        }, []);

        return null;
    };

    return (<>
        <Outlet/>
        <PosthogInit/>
    </>);
}
