import {Link, NavLink} from "@remix-run/react";
import {useCallback}   from "react";
import {ToastAction}   from "~/components/ui/toast";
import {useToast}      from "~/hooks/use-toast";

export const Footer = () => {
    const {toast} = useToast();

    const startPlaceholder = useCallback(() => {
        location.href = "mailto:start@chks.tech?&subject=CHKS Interesse";
    }, []);

    const availableSoon = (e: any) => {
        e.preventDefault();

        toast({
            title      : "Binnenkort beschikbaar",
            description: "Toch erg nieuwsgierig?",
            action     : (
                <ToastAction altText={"Contact"}
                             onClick={startPlaceholder}>
                    Contact
                </ToastAction>
            ),
        });
    };

    return (
        <footer className="backdrop-blur-3xl saturate-200 pt-4">
            <div className="container mx-auto px-8">
                <div className="w-full flex flex-col md:flex-row py-6">
                    <div className="flex-1 mb-6 text-black">
                        <button className="text-pink-600 flex flex-row no-underline hover:no-underline font-bold text-2xl lg:text-4xl">
                            <img src={"/chks.webp"} className="h-9 fill-current inline" alt="Logo"/>
                            <h2 className={"text-white"}>
                                CHKS
                            </h2>
                        </button>
                    </div>
                    <div className="flex-1">
                        <p className="uppercase text-neutral-400 md:mb-6">
                            Wettelijk
                        </p>
                        <ul className="list-reset mb-6">
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <button onClick={availableSoon} className="text-left no-underline hover:underline text-neutral-300 hover:text-pink-500">
                                    Algemene voorwaarden
                                </button>
                            </li>
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <button onClick={availableSoon} className="text-left no-underline hover:underline text-neutral-300 hover:text-pink-500">
                                    Privacy beleid
                                </button>
                            </li>
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <button onClick={availableSoon} className="text-left no-underline hover:underline text-neutral-300 hover:text-pink-500">
                                    Cookie verklaring
                                </button>
                            </li>
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <button onClick={availableSoon} className="text-left no-underline hover:underline text-neutral-300 hover:text-pink-500">
                                    Disclaimer
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div className="flex-1">
                        <p className="uppercase text-neutral-400 md:mb-6">
                            Social
                        </p>
                        <ul className="list-reset mb-6">
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <Link to={"https://www.linkedin.com/company/chks-nl"}
                                      target={"_blank"}
                                      rel="noreferrer"
                                      className="text-left no-underline hover:underline text-neutral-300 hover:text-pink-500">
                                    Linkedin
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="flex-1">
                        <p className="uppercase text-neutral-400 md:mb-6">
                            CHKS
                        </p>
                        <ul className="list-reset mb-6">
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <Link to={"https://dashboard.chks.tech/login"} className="text-left no-underline hover:underline text-neutral-300 hover:text-pink-500">
                                    Inloggen
                                </Link>
                            </li>
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <Link to={"https://dashboard.chks.tech/register"} className="text-left no-underline hover:underline text-neutral-300 hover:text-pink-500">
                                    Account aanmaken
                                </Link>
                            </li>
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <button onClick={availableSoon} className="text-left no-underline hover:underline text-neutral-300 hover:text-pink-500">
                                    Over Ons
                                </button>
                            </li>
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <button onClick={availableSoon} className="text-left no-underline hover:underline text-neutral-300 hover:text-pink-500">
                                    Contact
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};
