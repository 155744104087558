import {Link, NavLink}         from "@remix-run/react";
import {useCallback, useState} from "react";
import {Button}                                                                                                                       from "~/components/ui/button";
import {Drawer, DrawerClose, DrawerContent, DrawerDescription, DrawerFooter, DrawerOverlay, DrawerPortal, DrawerTitle, DrawerTrigger} from "~/components/ui/drawer";
import {Separator}                                                                                                                    from "~/components/ui/separator";

export const NavigationBar = () => {
    const [open, setOpen] = useState(false);

    const startPlaceholder = useCallback(() => {
        location.href = "mailto:start@chks.tech?&subject=CHKS Interesse";
    }, []);

    return (
        <nav id="header" className={` w-full z-40 top-0 text-white 2xl:py-4 2xl:rounded-xl`}>
            <div
                className="w-full 2xl:w-4/6 backdrop-blur-xl backdrop-saturate-50 2xl:rounded-xl px-2 mx-auto flex flex-wrap items-center justify-between mt-0 py-4">
                <div className="pl-4 flex flex-row items-center justify-center">
                    <NavLink to={"/"}
                             className="flex flex-row justify-center items-center gap-x-1 text-white no-underline hover:no-underline font-bold text-4xl">
                        <img src={"/chks.webp"} className="h-10" alt="Logo"/>
                        <h1 id="chks_title" className={"text-white mt-2 border-b-2 -pb-4 border-pink-600"}>
                            CHKS
                        </h1>
                    </NavLink>
                </div>
                <div className="block md:hidden z-20 pr-4">
                    <Drawer direction={"right"} modal={false} open={open} onOpenChange={setOpen}>
                        <DrawerTrigger asChild={true} aria-controls={"radix-:R155:"}>
                            <button
                                className="flex items-center p-1 text-purple-400 hover:text-neutral-900 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                                <svg className="fill-current h-6 w-6" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <title>Menu</title>
                                    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
                                </svg>
                            </button>
                        </DrawerTrigger>
                        <DrawerPortal>
                            <DrawerOverlay className={"hidden"}/>
                            <DrawerContent
                                className="right-2 -mt-2 top-2 bottom-2 absolute z-50 bg-neutral-50 outline-none w-full h-full flex rounded-none px-4">
                                <DrawerClose className={"flex flex-row justify-end items-end"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={"relative top-0 right-0 w-10 h-10 fill-neutral-800"}>
                                        <path
                                            d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z"></path>
                                    </svg>
                                </DrawerClose>
                                <DrawerTitle className="font-bold mb-2 mt-16 text-neutral-800 text-4xl">
                                    Wat zoek je?
                                </DrawerTitle>
                                <DrawerDescription className="text-neutral-600 mb-2 flex flex-col gap-y-4">
                                    <Separator/>
                                    <Button asChild={true}
                                            className={"bg-gradient-to-r from-pink-500 via-pink-500 to-purple-500"}
                                            onClick={() => setOpen(false)}
                                            size={"lg"}>
                                        <Link to={"https://dashboard.chks.tech/register"}>
                                            Gratis proberen
                                        </Link>
                                    </Button>
                                    <Separator/>
                                    <Button asChild={true}
                                            variant={"outline"}
                                            onClick={() => setOpen(false)}
                                            size={"lg"}>
                                        <NavLink to={"/#pricing"}>
                                            Prijzen
                                        </NavLink>
                                    </Button>
                                    <Button asChild={true}
                                            variant={"outline"}
                                            onClick={() => setOpen(false)}
                                            size={"lg"}>
                                        <NavLink to={"/developers"}>
                                            Ontwikkelaars
                                        </NavLink>
                                    </Button>
                                    <Button asChild={true}
                                            variant={"outline"}
                                            onClick={() => setOpen(false)}
                                            size={"lg"}>
                                        <NavLink to={"/integrations"}>
                                            Integraties
                                        </NavLink>
                                    </Button>
                                    <Separator/>
                                </DrawerDescription>
                                <DrawerFooter className={"flex flex-row justify-between items-center"}>
                                    <div className={"w-full flex flex-col gap-y-6"}>
                                        <div className={"w-full flex flex-row justify-between gap-x-4 items-center"}>
                                            <Button size={"lg"} className={"bg-purple-500 w-1/2"} asChild={true}>
                                                <Link to={"https://dashboard.chks.tech/login"}>
                                                    Inloggen
                                                </Link>
                                            </Button>
                                            <Button size={"lg"} variant={"secondary"} className={"w-1/2"} asChild={true}>
                                                <Link to={"https://dashboard.chks.tech/register"}>
                                                    Registreren
                                                </Link>
                                            </Button>
                                        </div>
                                        <div className={"w-full flex flex-row justify-between items-center"}>
                                            <div
                                                className={"flex flex-row justify-center items-center gap-x-1 text-white no-underline hover:no-underline font-bold text-4xl"}>
                                                <img src={"/chks.webp"} className="h-8" alt="Logo"/>
                                                <h2 id="chks_title" className={"text-black mt-2 text-md border-b-2 -pb-4"}>
                                                    CHKS
                                                </h2>
                                            </div>
                                            <Button variant={"link"} onClick={startPlaceholder}>
                                                Contact
                                            </Button>
                                        </div>
                                    </div>
                                </DrawerFooter>
                            </DrawerContent>
                        </DrawerPortal>
                    </Drawer>
                </div>
                <div className="w-full flex-grow md:flex md:items-center md:w-auto hidden mt-2 lg:mt-0 text-black md:p-0 z-20"
                     id="nav-content">
                    <ul className="list-reset md:flex justify-end flex-1 items-center">
                        <li className="mr-3">
                            <NavLink to={"#pricing"}
                                     className="inline-block text-xl font-medium text-white no-underline hover:text-pink-400 hover:text-underline py-2 px-2 lg:px-4">
                                Prijzen
                            </NavLink>
                        </li>
                        <li className="mr-3">
                            <NavLink to={"/integrations"}
                                     className="inline-block text-xl font-medium text-white no-underline hover:text-pink-400 hover:text-underline py-2 px-2 lg:px-4">
                                Integraties
                            </NavLink>
                        </li>
                        <li className="mr-3">
                            <NavLink to={"/developers"}
                                     className="inline-block text-xl font-medium text-white no-underline hover:text-pink-400 hover:text-underline py-2 px-2 lg:px-4">
                                Ontwikkelaars
                            </NavLink>
                        </li>
                    </ul>
                    <Link id="navAction"
                             to={"https://dashboard.chks.tech/register"}
                             className="mx-auto md:mx-0 hover:underline bg-pink-600 text-white rounded font-bold text-lg mt-4 md:mt-0 py-3 lg:py-4 px-6 lg:px-8 shadow focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                        Gratis proberen
                    </Link>
                </div>
            </div>
        </nav>
    );
};
